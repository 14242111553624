import { isUndefined, noop } from 'lodash/fp';
import { useCallback, useEffect, useRef, useState } from 'react';
import { focusManager } from 'react-query';

import history from '@portals/browser-history';

import { OnOpenParams } from './async-confirmation';
import { useConfirmationModal } from './modals';

export const useIsTabInFocus = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleWindowFocus = useCallback(() => setIsVisible(true), []);
  const handleWindowBlur = useCallback(() => setIsVisible(false), []);

  useEffect(() => {
    focusManager.setEventListener(() => {
      if (!isUndefined(window) && window.addEventListener) {
        window.addEventListener('focus', handleWindowFocus);
        window.addEventListener('blur', handleWindowBlur);
      }

      return () => {
        window.removeEventListener('focus', handleWindowFocus);
        window.removeEventListener('blur', handleWindowBlur);
      };
    });
  }, [handleWindowBlur, handleWindowFocus]);

  return isVisible;
};

export const useConfirmNavigation = ({
  isActive,
  confirmationModal: { title, description, confirmationLabel, cancelLabel },
  onConfirm = noop,
  onCancel = noop,
}: {
  isActive: boolean;
  confirmationModal: OnOpenParams;
  onConfirm?: () => void;
  onCancel?: () => void;
}) => {
  // Used to store reference to `history.block` callback that unblocks the navigation
  const unblockNavigationRef = useRef<ReturnType<typeof history.block>>();

  // Used to prevent numerous modal from opening while one is already active
  const isConfirmationModalActive = useRef(false);

  // Stores the target URL which awaits for confirmation.
  // Confirmation modal is toggled when `targetLocation !== null`
  const [targetLocation, setTargetLocation] = useState(null);

  const asyncConfirmation = useConfirmationModal();

  const blockNavigation = useCallback(async () => {
    // Returns a callback which unblocks navigation when called
    unblockNavigationRef.current = history.block((targetLocation) => {
      if (targetLocation?.pathname) {
        setTargetLocation(targetLocation.pathname);

        return false;
      }

      return true;
    });
  }, []);

  const confirmNavigation = useCallback(async () => {
    isConfirmationModalActive.current = true;

    const isConfirmed = await asyncConfirmation({
      title,
      description,
      confirmationLabel,
      cancelLabel,
    });

    if (isConfirmed) {
      // Unblock navigation
      unblockNavigationRef.current();
      unblockNavigationRef.current = null;

      // Navigate to the requested URL
      history.push(targetLocation);
      onConfirm();
    } else {
      // Remove requested target URL
      setTargetLocation(null);
      onCancel();
    }

    isConfirmationModalActive.current = false;
  }, [
    asyncConfirmation,
    cancelLabel,
    confirmationLabel,
    description,
    onCancel,
    onConfirm,
    targetLocation,
    title,
  ]);

  // Display confirmation modal if `targetLocation` was set from `history.block` callback, and
  // there isn't an already active confirmation modal in view
  useEffect(
    function displayConfirmationModal() {
      if (targetLocation && !isConfirmationModalActive.current) {
        confirmNavigation();
      }
    },
    [confirmNavigation, targetLocation]
  );

  useEffect(
    function setNavigationBlockingState() {
      if (isActive) {
        // Block navigation & set requested navigation URL in `targetLocation`, which will
        // toggle the confirmation modal
        blockNavigation();
      } else {
        // Unblock navigation if `shouldConfirm` flag changed to false and navigation is
        // currently blocked
        if (unblockNavigationRef.current) {
          unblockNavigationRef.current();
          unblockNavigationRef.current = null;
          isConfirmationModalActive.current = false;

          setTargetLocation(null);
        }
      }
    },
    [blockNavigation, isActive]
  );
};
